.loader {
  margin-top: 25vh;
  width: 100%;
  perspective: 1000px;
  position: relative;
  opacity: 0;
  animation-name: hideLoaderInStart;
  animation-duration: 100s;
}

.horizontal-road {
  width: 18rem;
  height: 6px;
  background-color: #555;
  position: absolute;
  top: 9rem;
  left: -5rem;
}

.vertical-loop-road {
  width: 10rem;
  height: 10rem;
  border: 6px solid #555;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  perspective: 1000px;
}

.loader-car {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: -30px;
  left: -20px;
  transform-style: preserve-3d;
  animation: carMoveVertical 1s infinite;
}

@media (max-width: 37.5em) {
  .loader-car {
    top: -25px;
  }
}
@media (min-width: 120em) {
  .loader-car {
    top: -35px;
  }
}

@keyframes carMoveVertical {
  30% {
    transform: translateX(8rem);
  }
  35% {
    transform: translate(12rem, -2rem) rotateZ(-50deg);
  }
  40% {
    transform: translate(12rem, -3rem) rotateZ(-70deg);
  }
  45% {
    transform: translate(12.5rem, -4rem) rotateZ(-90deg);
  }

  55% {
    transform: translate(10rem, -7rem) rotateZ(-150deg);
  }

  60% {
    transform: translate(8rem, -7rem) rotateZ(-200deg);
  }
  65% {
    transform: translate(5.5rem, -4rem) rotateZ(-270deg);
  }
  70% {
    transform: translate(6.5rem, -2rem) rotateZ(-310deg);
  }
  75% {
    transform: translate(8rem, 0rem) rotateZ(-360deg);
  }

  100% {
    transform: translateX(18rem) rotateZ(-360deg);
  }
}

@keyframes hideLoaderInStart {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
