.app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.main {
  padding: 3rem 5rem;
}

h2 {
  font-size: 1.8rem !important;
}
h3 {
  font-size: 1.6rem !important;
}

.pagination * {
  font-size: 1.8rem !important;
}
.pagination {
  margin: 2rem auto;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  height: 6rem !important;
  background: #434343;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
  background: white;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(81, 81, 83, 0.808);
}

@media (max-width: 37.5em) {
  main {
    padding: 3rem !important;
  }
}
