.footer {
  padding: 3rem 5rem;
  background-color: #164863;
  min-height: 10rem;
  display: grid;
  gap: 2rem;
}
.footer-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1rem;
}

.footer-link {
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 1.8rem;
  color: rgb(179, 219, 219);
  justify-self: center;
}
.footer-link:hover {
  color: white;
  text-decoration: underline;
}
.footer-rights {
  color: white;
  margin: 0 auto;
  text-align: center;
  font-size: 1.2rem;
}
