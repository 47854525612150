.landing-page {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  position: relative;
  gap: 2rem;
}
.page-title {
  font-weight: 700;
  letter-spacing: 0.75px;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.landing-page-header {
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  background-color: white;
}
.popular-brands-section {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  gap: 1rem;
  margin-bottom: 2rem;
}
.popular-brands {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}
.see-more {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 600;
}
.see-more:hover {
  color: #6aa5c4;
}
.see-more:hover * {
  stroke: #6aa5c4;
}

.landing-page-content {
  display: grid;
  grid-template-columns: 20% 80%;
  flex: 1;
}

.filters-section {
  background-color: #f3f8ff;
  border-radius: 5px;
  border: 2px solid rgb(175, 172, 172);
  padding: 2rem 2rem 2rem;
  height: 75vh;
  display: grid;
  align-content: start;
  gap: 1.5rem;
}
.filters-container {
  display: grid;
  gap: 1.8rem;
}

.filter-btn {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
}
.filter-input {
  outline: none;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 100%;
  justify-self: center;
  border: 2px solid #d8d8d8;
  font-size: 1.6rem;
  position: relative;
}
.filter-input:focus {
  border: 2px solid #2684ff;
}
::placeholder {
  color: #8a8a8a;
  opacity: 1; /* Firefox */
}

.filter-container {
  display: grid;
  font-size: 1.6rem;
  gap: 7px;
  position: relative;
}
.filter-container h3 {
  font-weight: 500;
}
.order-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  font-size: 1.6rem;
  font-weight: 400;
}
.filter-title {
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 5px;
}

.loading-error {
  display: flex;
  justify-content: center;
  padding: 3rem;
  font-size: 2.5rem;
  color: red;
  font-weight: 400;
}

.filters-modal-btns-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.modal-btn {
  padding: 1rem 2rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  color: white;
  letter-spacing: 0.5px;
}
.cancel-btn {
  background-color: #fa6d6d;
}
.confirm-btn {
  background-color: #50f350;
}

@media (max-width: 1200px) {
  .landing-page-content {
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, min-content);
  }
  .filters-section {
    height: min-content;
    position: relative;
    width: min-content;
    padding: 0;
  }
  .filter-btn {
    background-color: rgb(239, 239, 239);
    padding: 1rem 2rem;
    border-radius: 5px;
  }
  .filters-container {
    position: absolute;
    background-color: #f3f8ff;
    padding: 3rem;
    box-sizing: border-box;
    width: 85vw;
    z-index: 100;
    border-radius: 5px;
  }
}
