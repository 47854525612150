.header-dropdown {
  display: grid;
  position: absolute;
  top: 5rem;
  left: 1rem;
  background-color: #164863;
  color: white;
  border-radius: 5px;
  z-index: 10;
}

.dropdown-option {
  padding: 5px 0px;
  font-weight: 600;
  display: flex;
  gap: 6px;
  align-items: center;
}

.option-icon {
  width: 2.5rem;
  overflow: hidden;
  height: 2.5rem;
}

.dropdown-option {
  padding: 8px 3rem 8px 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
}
.dropdown-option:hover {
  background-color: white;
  color: #164863;
  transition: 0.3s;
}
.dropdown-option:hover * {
  color: #164863;
  fill: #164863;
  transition: 0.3s;
}

@media (max-width: 37.5em) {
  .header-dropdown {
    left: -9rem;
    top: 120%;
  }
}
