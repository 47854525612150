* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}
html {
  /* 1rem is 10 pixel */
  font-size: 62.5%;
  box-sizing: border-box;
}

/* use of em unit to handle different screen widths and zoom in event in browser
use of rem unit with diffrent percentages according to different screen widths
allow to automatically adapt dimensions */

/* 75em=1200px */
@media (max-width: 75em) {
  html {
    font-size: 60% !important;
  }
}
/* 65.25em=900px */
@media (max-width: 56.25em) {
  /* 1rem is 9 pixel */
  html {
    font-size: 56.25% !important;
  }
}

/* 65.25em=600px */
@media (max-width: 37.5em) {
  /* 1rem is 9 pixel */
  html {
    font-size: 50% !important;
  }
}

/* 112.5em=1800px */
@media (min-width: 112.5em) {
  /* 1rem is 12 pixel */
  html {
    font-size: 75% !important;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body h1 h2 {
  font-family: 'Roboto', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
