.header {
  padding: 2rem 8rem 2rem 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #6aa5c4;
  font-size: 1.6rem;
}

.header-api-auth {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.header-api {
  color: black;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 3px;
  font-size: 1.8rem;
}

.header-api:hover {
  text-decoration: none;
  color: yellow;
}

.header-login {
  color: #162353;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 1.8rem;
  padding: 0.6rem 2rem;
  background-color: white;
  border-radius: 5px;
}

.header-login:hover {
  text-decoration: none;
  color: yellow;
  background-color: #162353;
  transition: 0.3s;
}

.header-logo {
  width: 200;
  height: 7.4rem;
}
.header-user {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 4rem;
  position: relative;
}
.header-user:hover {
  cursor: pointer;
}
.header-user-pic {
  height: 6rem;
  width: 6rem;
  border: white 3px solid;
  border-radius: 8rem;
  object-fit: cover;
}

.header-user-name {
  font-weight: 600;
  font-size: 1.8rem;
}
.header-user:hover .header-user-name {
  color: white;
  transition: 0.3s;
}
.user-dropdown {
  position: absolute !important;
  top: -15px !important;
}

@media (max-width: 37.5em) {
  .header {
    padding: 2rem;
    font-size: 1.6rem;
  }
}

@media (max-width: 31.5em) {
  .header-logo {
    width: 12rem;
    height: 4rem;
  }
  .header-user-pic {
    height: 5rem;
    width: 5rem;
  }
  .header-api-auth {
    gap: 2rem;
  }
  .header-user-name {
    display: none;
  }
}
